<template>
  <div>
    <label>{{ $t('Permitted to') }}</label>
    <vue-select
      :options="roles.options"
      class="input cus-select cus-multi-dropdown"
      v-model="roles.selected"
      @input="changeRoles"
      multiple
    ></vue-select>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "RolesDropdown",
  props: {
    selectedRoles: {}
  },
  data() {
    return {
      roles: {
        options: [],
        selected: []
      },
    }
  },
  computed: {
    ...mapState({
      rolesList: (state) => state.system_settings.rolesList,
    }),
  },
  async created() {
    if (this.rolesList.length == 0) {
      await this.getRoles();
    }
    if (this.rolesList.length != 0) {
      this.roles = {
        options: [],
        selected: ""
      };
      this.rolesList.forEach(el => {
        this.roles.options.push({
          label: el.name,
          code: el.id,
        });
      });

      this.roles.selected = this.selectedRoles;
    }
  },
  methods: {
    ...mapActions("system_settings", {
      getRoles: "getRoles",
    }),
    changeRoles() {
      this.$emit("update:selectedRoles", this.roles.selected);
    }
  },
  watch: {
    selectedRoles: function(val) {
      this.roles.selected = val;
    }
  },
}
</script>