<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Tourism profile') }}
    </p>
    <section class="content-margin">
      <div class="d-flex align-items-center mb-15 px-7 filter-block">
        <label>{{ $t('Select a category') }}</label>
        <vue-select
          :options="tourismProfile"
          class="input cus-select profile-dropdown mx-10"
          v-model="profile.selected"
          label="tour_name"
          @input="changeProfile"
        ></vue-select>
        <button class="primary-btn btn-limit-width bg-yellow" @click="openCreateModal()">
          {{ $t('New profile') }}
        </button>
        <button v-if="selectedProfile" class="primary-btn btn-limit-width bg-red1 mx-10" @click="OpenDelConfirm()">
          {{ $t('Delete') }}
        </button>
      </div>

      <div class="mb-20 px-7" v-if="selectedProfile">
        <!-- <label class="mb-2">{{ $t('Approved for use for') }}</label>
        <tags-block-two :tags="tags" /> -->
        <RolesDropdown :selectedRoles.sync="selectedRoles"></RolesDropdown>
      </div>

      <div class="px-7 mb-15" v-if="selectedProfile">
        <div class="d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_default" id="is_default"></v-checkbox>
            <label class="mx-5" for="is_default">{{ $t('As a default profile') }}</label>
          </div>
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_shaniv_default" id="is_shaniv_default"></v-checkbox>
            <label class="mx-5" for="is_shaniv_default">{{ $t('As shaniv default') }}</label>
          </div>
        </div>
      </div>

      <div class="mb-20" v-if="selectedProfile">
        <p class="px-7">{{ $t("Percentage of cash profit") }}</p>
        <div class="d-flex flex-wrap align-items-end">
          <div class="px-7 small-input">
            <label class="label">{{ $t("Direct sales commission") }}</label>
            <input class="input text-center color-white bg-green1" type="text" v-model="direct_sale_fee" @keypress="isNumber($event)">
          </div>
          <div class="px-7 small-input">
            <label class="label">{{ $t("Referral sales commission") }}</label>
            <input class="input text-center color-white bg-green1" type="text" v-model="affiliate_sale_fee" @keypress="isNumber($event)">
          </div>
        </div>
        <p class="px-7 mt-25">{{ $t("Credit profit percentage") }}</p>
        <div class="d-flex flex-wrap align-items-end">
          <div class="px-7 small-input">
            <label class="label">{{ $t("Direct sales commission") }}</label>
            <input class="input text-center color-white bg-blue" type="text" v-model="credit_sale_fee" @keypress="isNumber($event)">
          </div>
          <div class="px-7 small-input">
            <label class="label">{{ $t("Sales commission direct link") }}</label>
            <input class="input text-center color-white bg-blue" type="text" v-model="link_sale_fee" @keypress="isNumber($event)">
          </div>
          <div class="px-7 small-input">
            <label class="label">{{ $t("Indirect link sales commission") }}</label>
            <input class="input text-center color-white bg-blue" type="text" v-model="second_link_sale_fee" @keypress="isNumber($event)">
          </div>
        </div>
        <p class="px-7 mt-25">{{ $t("Frontal representative fee") }} ({{ $t("Automatic amount according to the currency of the transaction") }})</p>
        <div class="d-flex flex-wrap align-items-end">
          <div class="px-7 small-input">
            <label class="label">{{ $t("Direct sales commission") }}</label>
            <input class="input text-center color-white bg-yellow" type="text" v-model="front_sale_fee" @keypress="isNumber($event)">
          </div>
          <div class="px-7 small-input">
            <label class="label">{{ $t("Referral sales commission") }}</label>
            <input class="input text-center color-white bg-yellow" type="text" v-model="direct_front_sale_fee" @keypress="isNumber($event)">
          </div>
          <div v-if="false" class="px-7 small-input">
            <label class="label">{{ $t("Minimum frontal representative fee") }}</label>
            <input class="input text-center color-white bg-red2" type="text" value="no-api" @keypress="isNumber($event)">
          </div>
        </div>
      </div>
    </section>

    <div class="content-margin mb-20 d-flex justify-content-center" v-if="selectedProfile">
      <button :disabled="!selectedProfile" class="primary-btn mt-20" @click="updateProfile">
        {{ $t("Save") }}
      </button>
    </div>
    <CreateProfileModal @createProfile="createProfile" ref="createProfileModal"></CreateProfileModal>
    <ConfirmModal @confirmData="confirmData">
      <template v-slot:modal-content>
        {{ $t('Are you sure you want to delete {NAME}?', {NAME: selectedProfile ? selectedProfile.tour_name : ""}) }}
      </template>
    </ConfirmModal>
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RolesDropdown from '../../../components/management/RolesDropdown.vue';
import CreateProfileModal from '../../../components/management/CreateProfileModal.vue';
import ConfirmModal from '../../../components/management/ConfirmModal.vue';
export default {
  name: 'CPTourismProfiles',
  components: {
    RolesDropdown,
    CreateProfileModal,
    ConfirmModal
  },
  data() {
    return {
      profile: {
        options: [],
        selected: ""
      },
      direct_sale_fee: "",
      affiliate_sale_fee: "",
      credit_sale_fee: "",
      link_sale_fee: "",
      second_link_sale_fee: "",
      front_sale_fee: "",
      direct_front_sale_fee: "",
      selectedRoles: [],
      is_default: false,
      is_shaniv_default: false,
    }
  },
  computed: {
    ...mapState({
      tourismProfile: (state) => state.category_products.tourismProfile,
    }),
    selectedProfile() {
      let selectedProfile = null;

      if (this.profile && this.profile.selected) {
        selectedProfile = this.profile.selected;
      }

      return selectedProfile;
    }
  },
  async created() {
    if (!this.tourismProfile || this.tourismProfile.length === 0) {
      await this.getTourismProfile();
    }
    await this.autoSelectProfile();
  },
  methods: {
    ...mapActions("category_products", {
      getTourismProfile: "getTourismProfile",
      createTourismProfile: "createTourismProfile",
      updateTourismProfile: "updateTourismProfile",
      deleteTourismProfile: "deleteTourismProfile",
    }),
    async clearForm() {
      this.direct_sale_fee = "";
      this.affiliate_sale_fee = "";
      this.credit_sale_fee = "";
      this.link_sale_fee = "";
      this.second_link_sale_fee = "";
      this.front_sale_fee = "";
      this.direct_front_sale_fee = "";
      this.selectedRoles = [];
      this.is_default = false;
      this.is_shaniv_default = false;
    },
    openCreateModal() {
      this.$modal.show('newProfileModal');
    },
    createProfile(pName) {
      this.createTourismProfile({
        "tour_name": pName
      }).then(async res => {
        if (res != "error") {
          await this.getTourismProfile();
          let createdProfileId = res && res.id ? res.id : null;
          await this.autoSelectProfile(createdProfileId);
        }
        this.$refs.createProfileModal.closeModal();
      });
    },
    async updateProfile() {
      if (!this.selectedProfile) {
        return;
      }
      const id = this.selectedProfile.id;
      let params = {};
      params.direct_sale_fee = this.direct_sale_fee;
      params.affiliate_sale_fee = this.affiliate_sale_fee;
      params.credit_sale_fee = this.credit_sale_fee;
      params.link_sale_fee = this.link_sale_fee;
      params.second_link_sale_fee = this.second_link_sale_fee;
      params.front_sale_fee = this.front_sale_fee;
      params.direct_front_sale_fee = this.direct_front_sale_fee;
      params.is_default = !!this.is_default;
      params.is_shaniv_default = !!this.is_shaniv_default;

      let roles = [];
      this.selectedRoles.forEach(el => {
        roles.push(el.label);
      });
      params.roles = roles;

      await this.updateTourismProfile({
        id: id,
        params: params
      });
      this.getTourismProfile();
    },
    async changeProfile() {
      await this.clearForm();

      if (this.selectedProfile) {
        if (this.selectedProfile && this.selectedProfile.Roles) {
          this.selectedProfile.Roles.forEach(el => {
            this.selectedRoles.push({
              label: el.name,
              code: el.id
            })
          });
        }

        this.direct_sale_fee = this.selectedProfile.direct_sale_fee;
        this.affiliate_sale_fee = this.selectedProfile.affiliate_sale_fee;
        this.credit_sale_fee = this.selectedProfile.credit_sale_fee;
        this.link_sale_fee = this.selectedProfile.link_sale_fee;
        this.second_link_sale_fee = this.selectedProfile.second_link_sale_fee;
        this.front_sale_fee = this.selectedProfile.front_sale_fee;
        this.direct_front_sale_fee = this.selectedProfile.direct_front_sale_fee;
        this.is_default = this.selectedProfile.is_default;
        this.is_shaniv_default = this.selectedProfile.is_shaniv_default;
      }
    },
    OpenDelConfirm() {
      if (!this.selectedProfile) {
        return;
      }
      this.$modal.show('confirmModal');
    },
    confirmData() {
      if (!this.selectedProfile) {
        return;
      }
      const id = this.selectedProfile.id;
      this.deleteTourismProfile(id).then(async res => {
        if (!res.error) {
          await this.getTourismProfile();
          await this.autoSelectProfile();
        }
      });
      this.$modal.hide('confirmModal');
    },
    async autoSelectProfile(id = null) {
      if (this.tourismProfile && this.tourismProfile.length > 0) {
        let profileToSelect = this.tourismProfile[0];
        if (id) {
          profileToSelect = this.tourismProfile.find(p => p.id === id) || profileToSelect;
        }
        this.profile.selected = {...(profileToSelect)};
        this.profile = {...(this.profile)};
      }
      await this.changeProfile();
    }
  }
}
</script>

<style lang="scss" scoped>
  .small-input {
    max-width: 210px;
  }

  @media screen and (max-width: 635px) {
    .filter-block {
      flex-wrap: wrap;
      button {
        margin-top: 10px;
      }
    }
    .primary-btn {
      height: unset;
      min-height: 44px;
    }
    .w-25,
    .w-50,
    .w-75 {
      width: 100% !important;
    }
  }
</style>