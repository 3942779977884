<template>
  <modal
    :width="550"
    :adaptive="true"
    class="newProfileModal CEModal modal-scroll-bar"
    name="newProfileModal"
  >
    <div class="modal-content">
      <div class="validation-form-group"
        :class="{ 'form-group--error': $v.newProfile.$error }">
        <label>{{ $t('New profile') }} *</label>
        <input
          type="text" 
          class="input"
          v-model="newProfile"
          @input="$v.newProfile.$touch"
        />
        <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newProfile.required">
          {{ $t('This is a required field.') }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="primary-btn primary-btn_sm mx-5" @click="saveNewProfile">
        {{ $t('Confirm') }}
      </button>
      <button class="primary-btn primary-btn_sm mx-5 bg-2" @click="closeModal">
        {{ $t('Cancel') }}
      </button>
    </div>
  </modal>  
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateProfileModal",
  mixins: [validationMixin],
  data() {
    return {
      newProfile: ""
    }
  },
  validations: {
    newProfile: {
      required
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide('newProfileModal');
      this.$v.$reset();
      this.newProfile = "";
    },
    saveNewProfile() {
      this.$v.$touch();
      if (
        this.$v.$anyError
      ) {
        return;
      }
      this.$emit("createProfile", this.newProfile);
    },
  }
}
</script>

<style lang="scss" scoped>
  .newProfileModal {
    .modal-footer {
      padding: 0 40px 30px;
    }
  }
</style>